.header {
  border: 1px solid #a792b0;
  display: flex;
  justify-content: space-between;
  padding: 17px;
}

.logoRootContainer {
  text-align: center;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.logo {
  max-width: 50px;
  max-height: 50px;
}
.websiteName {
  font-size: 18px;
  cursor: pointer;
  font-weight: 300;
  color: #a792b0;
}

.MainMenuRootContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.singleMenuItem {
  font-size: 16px;
  cursor: pointer;
  color: #a792b0;
}

/* .loginButtonRootContainer {
} */

.loginButton {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #a792b0;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s ease-out;
  color: #a792b0;
}

.loginButton:hover {
  background-color: black;
  color: #a792b0;
}
